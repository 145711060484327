<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="top">
      <v-flex xs12 align-self-start v-if="top.image">
        <v-img
          cover
          height="450px"
          class="align-end"
          :src="mediaURL + top.image"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Webinars</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-12>
        <v-layout wrap justify-start fill-height>
          <v-flex
            xs12
            sm6
            lg4
            align-self-center
            pa-4
            v-for="(item, i) in webinarsList"
            :key="i"
          >
            <v-card
              elevation="3"
              class="rounded-xl"
              @click="redirect(item.key)"
            >
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-img cover height="200px" :src="item.img"></v-img>
                </v-flex>
                <v-flex xs12 pa-2>
                  <span
                    :style="
                      $vuetify.breakpoint.name == 'lg'
                        ? 'font-family: TitSemibold;font-size:20px'
                        : $vuetify.breakpoint.name == 'xl'
                        ? 'font-family: TitSemibold;font-size:20px'
                        : 'font-family: TitSemibold;font-size:18px'
                    "
                    >"{{ item.name }}"</span
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      membership: [],
      top: [],
      evimg: require("./../../assets/images/ev.jpg"),
      webinarsList: [
        {
          name: "World Wetlands Day",
          img: require("./../../assets/images/ev.jpg"),
          key: 0,
        },
        {
          name: "Cavernicoles: Denizens of the Dark",
          img: require("./../../assets/images/saiii.jpeg"),
          key: 1,
        },
        {
          name: "Discover Karst and Cave Geology",
          img: require("./../../assets/images/karst.jpeg"),
          key: 2,
        },
        {
          name: "International Art Day and the International Year for Caves and Karst",
          img: require("./../../assets/images/eapril.jpeg"),
          key: 3,
        },
        
        {
          name: "Aquifers and caves: the groundwater perspective",
          img: require("./../../assets/images/eJune.jpeg"),
          key: 4,
        },
         {
          name: "Traces of ancient life: a look at pollen grains from mines",
          img: require("./../../assets/images/eNov.jpg"),
          key: 5,
        },
        {
          name: "The Underexplored Habitat of Coastal Caves",
          img: require("./../../assets/images/eMarch.jpg"),
          key: 6,
        },
      ],
    };
  },
  methods: {
    redirect(k) {
      this.$router.push("/Webinars?k=" + k);
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
